import React from "react";
import "../../styles/sell_machine.css";

const SellMachine = () => {
  return (
    <React.Fragment>
      <div className="sell-bg">
        <div className="flex justify-center">
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
            <div class="p-2 sm:p-10 text-center">
              <div
                class=""
                className="flex justify-center  lg:w-[380px] sm:w-[300px] h-[300px]  items-center max-w-sm rounded overflow-hidden shadow-lg hover:bg-pink card-1_img"
              >
                <div className="space-y-10">
                  <div className="px-6 py-4">
                    <div className="space-y-5 p-4 bg-gray-400">
                      <a
                        className="font-bold text-2xl mb-2 text-white cursor-pointer"
                        href="/sell-machine"
                      >
                        Sell My Machine
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2 sm:p-10 text-center">
              <div className="flex justify-center lg:w-[380px] sm:w-[300px] h-[300px]  items-center max-w-sm rounded overflow-hidden shadow-lg hover:bg-pink card-2_img">
                <div className="space-y-10">
                  <div className="px-6 py-4">
                    <div className="space-y-5 p-4 bg-gray-400">
                      {/* eslint-disable  */}
                      <a
                        className="font-bold text-2xl mb-2 text-white cursor-pointer"
                        href="/machines"
                      >
                        Buy a Machine
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SellMachine;
