import { Item } from "react-html-email";
import IMG1 from "../../assets/images/Gallery/IMG1.jpg";
import IMG2 from "../../assets/images/Gallery/IMG2.jpg";
import IMG3 from "../../assets/images/Gallery/IMG3.jpg";
import IMG4 from "../../assets/images/Gallery/IMG4.png";
import IMG5 from "../../assets/images/Gallery/IMG5.png";
import IMG6 from "../../assets/images/Gallery/IMG6.png";
import IMG7 from "../../assets/images/Gallery/IMG7.png";
import IMG8 from "../../assets/images/Gallery/IMG8.png";
import IMG9 from "../../assets/images/Gallery/IMG9.png";

const GalleryComponents = () => {
  let ImgArray = [
    {
      img: IMG1,
    },
    {
      img: IMG2,
    },
    {
      img: IMG3,
    },
    {
      img: IMG4,
    },
    {
      img: IMG5,
    },
    {
      img: IMG6,
    },
    {
      img: IMG7,
    },
    {
      img: IMG8,
    },
    {
      img: IMG9,
    },
  ];
  return (
    <>
      <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
        <div className=" flex justify-center items-center grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-row py-10">
         {ImgArray.map((item)=>{
            return(
                <div className="flex flex-row justify-center h-full">
                  <div className="card flex flex-col justify-between bg-[#ffffff] w-96 h-[auto] rounded-md p-3 space-y-4 m-2 border border-slate-400">
                  <img
                  className="w-full h-80 hover:bg-cyan-300 object-cover"
                  src={item.img}
                  alt=""
                />
                  </div>
                </div>

            )
         })}
        </div>
      </div>
    </>
  );
};
export default GalleryComponents;
