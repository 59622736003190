import React from "react";
import OldiesByGoodiesList from "../../components/OldiesByGoodiesList";

const OldiesByGoodies = () => {
  return (
    <React.Fragment>
      <div>
        <OldiesByGoodiesList />
      </div>
    </React.Fragment>
  );
};
export default OldiesByGoodies;
