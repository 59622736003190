import { useState } from "react";
import AccordionView from "../../CommonComponent/accordion";
import videoIcon from "../../../assets/images/Icon/videoIcon.png";
import Question from "../../../assets/images/Icon/Question.png";
const FaqList = () => {
  const AccordionData = [
    {
      videoType: true,
      title: "Can I bust my CAT Lease?",
      description:
        "Release the equity you have likely built up in your CAT lease with the help of the team at DeWitt Equipment remarketing",
      link: "https://www.youtube.com/watch?v=6JhLvgPHIs0&t=0s",
      icon: videoIcon,
    },
    {
      videoType: true,
      title: "Can I Get the Equity out of my CAT Lease?",
      description:
        "Matt DeWitt Describes how he and the team at DeWitt Equipment Remarketing can help you out of your CAT lease and realize the Equity you have in it",
      link: "https://www.youtube.com/watch?v=_PJNRELBaR8=0s",
      icon: videoIcon,
    },
    {
      videoType: true,
      title: "How do I Get Fair Market Value for my Machines?",
      description:
        "Matt DeWitt explains how DeWitt Remarketing can help contractors realize the true equity in their machines.",
      link: "https://www.youtube.com/watch?v=2b9h_2Et7nY=0s",
      icon: videoIcon,
    },
    {
      videoType: true,
      title: "How can I get Lease Help for my Machines?",
      description:
        "DeWITT can help you to get out of a lease that isn’t working for you anymore.",
      link: "https://www.youtube.com/watch?v=Ss2XchpmB3A=0s",
      icon: videoIcon,
    },
    {
      videoType: true,
      title: "How can I get Lease Help for my Machines?",
      description:
        "When business changes, don’t default to sending your machines to the auction. Get more value and the most equity out of your investment. Let DeWitt equipment help you remarket your machine.",
      link: "https://www.youtube.com/watch?v=ztukn49bqxw=0s",
      icon: videoIcon,
    },
    {
      videoType: false,
      title: "What’s involved in consigning my machine?",
      description:
        "Let’s start with a picture of the machine serial number plate, side view of the machine, operator station, and hour meter. With this we can determine year,specifications, cosmetic condition and estimate price range. We then inspect the machine and recommend any actions required to assure the best possible sell price. Once the final consignment price is agreed upon, we create a consignment agreement for your review and signature.",
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: "How long will it take to sell my machine? ",
      description:
        "Most consignment agreements are for 90 days from the date of signature or delivery to our Bradford yard. The average time from signature (delivery) to payment is 75 days for heavy equipment and 45 days for compact equipment.",
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: "Why do you need my service history?",
      description:
        "All things being equal, the more detailed the service history the more you can expect for your machine. Buyers fear what they don’t know about your machine. Much can be learned from the external condition. The service history helps assure buyers of the internal condition, making it VERY important.",
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: "Can I continue to use my machine while it’s for sale?",
      description:
        "Yes, if you absolutely need to use the machine, DeWITT can bring buyers to your job site. The machine will need to be made available for the purchaser’s inspection. Obviously the easier it is for a buyer to buy, the better the outcome.",
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: "I don’t want the machine used anymore, what do we do next?",
      description:
        "Simply drop the machine at our Bradford Ontario yard and we will sell it from there.",
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `How do you determine my machine's value?`,
      description:
        "We determine the fair market value based upon years of experience and using industry data factoring in model age, hours of use and condition.",
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `Why should I use your service versus my dealer?`,
      description: `The <u><b>ONLY</b></u> thing we do is represent <u>your equipment</u> for the highest retail sale price possible. Period. <br><br> History has proven the OEM dealers have a fairly low success rate in terms of customer satisfaction when it comes to consigning machines. This is primarily due to the fact that consignment inventory is less important than their own used inventory or new sales and market share. Consequently, the dealer sales force often has little motivation to sell customer units.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `What’s the difference in price using DeWITT’s services over Dealer trade value or auction?`,
      description: `On average, our customers enjoy approximately 25%-30% over auction proceeds and around 20% more than dealer trade value.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `What do you mean by dealer “over allowance” on my used equipment?`,
      description: `Dealers will often use manufacturer programs or simply raise the price in order to pay more than “wholesale” or auction value for your trade. That’s why we recommend getting your best price before ever talking about a trade in. Dealers need to pay wholesale for their inventory in order to earn a “net” profit.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `So, you're saying my dealer gouged me on my trade value?`,
      description: `Not necessarily. Dealers are burdened with a lot of overhead costs required to stock parts, provide service as well as train their sales and service personnel. <br>They generally need to pay significantly less for your trade. Only in the rarest of circumstances will they pay real retail for a machine. Those are the rules.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `How is using DeWitt’s services a “lower risk” choice?`,
      description: `The auction value can fluctuate significantly from week to week and there are no guarantees in a true “unreserved” auction. Jutt like Football on <b>“any given Sunday”</b> …on any given auction day <u>there are no guarantees</u>. When you consign with DeWItt Equipment you not only have our full attention, <b>you remain in control and the machine isn’t sold until <u>you</u> say it’s sold.</b>`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `What makes you any different from any other buy/ sell used equipment company?`,
      description: `Great question! Most used equipment sales organizations want to buy your machine as low as possible and sell as high as possible. Makes sense. At DeWitt our ONLY business is representing your equipment for sale for the highest resale price possible. <u>That’s our whole business</u>. SIMPLE.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `Will you buy my machine?`,
      description: `Yes but generally <u>you don’t want anyone but another end user buying your equipment</u>. Because they want a good machine to produce for them, they are more willing to pay “fair market” for your machine. DeWITT provides an estimate of wholesale value and retail (fair market) value.<u> You don’t want us or anyone paying you wholesale.</u> Right?`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `Can you get me out of a lease?`,
      description: `Yes, almost assuredly. Depending upon where you are at in the lease term and how critical the circumstance, there is almost always a way out. Sometimes you will enjoy “lease equity” and we’ll pay you the difference between the sale (less our fee) and the payout of the remaining residual value owed to the leasing company. In more dire circumstances, there may be significant penalties involved in breaking the lease. You will still be significantly better off employing DeWITT to assure the highest possible resale price. Where there is a significant hardship, we are happy to work with you on fees and payment to help assure the best possible outcome for your company. <br><br>      Our Goal? <b>“To maximize the upside or minimize the downside”</b>`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `What’s fair market value?`,
      description: `Fair market value is the expected average sale price of equipment paid by the (end user contractor) buyer.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `What’s list price versus fair market value? List is the advertised price, often much higher than what t`,
      description: `List is the advertised price, often much higher than what the machine will transact at.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `Why are advertised machines priced so high?`,
      description: `Dealers often price machines 10%-15% over fair market value because they “can always come down but they can’t go up” in price. They also have a vested interest in assuring their various brands’ highest possible resale pricing.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `How does payment work?`,
      description: `We take a percentage of the retail sale price plus any costs (freight, cleaning, minor repairs or service etc.) agreed to by you in advance. <br>The fee can vary based on dollar volume or package size and <b>“We only do better when you do better”.</b> Once we negotiate the deal on your behalf and receive payment, we get you paid immediately. If there is a lease or finance balance to pay out, we pay it out and provide you the net proceeds. Simple!`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `Can I remove my consigned machine from my insurance?`,
      description: `As the equipment asset remains your property until you agree to the sale  price, we require our clients to maintain their insurance on any units consigned through DeWItt Equipment.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `What is Equipment Remarketing?`,
      description: `We use a number internet Remarketing strategies to get your used construction equipment in front of targeted prospective customers who’ve shown interest in similar construction equipment products.`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `Why is the auction pricing lower than fair market?`,
      description: `<u>The very nature of auction</u> sees buyers looking for a cheaper alternative or a <b>“steal”</b> on used equipment. There is more risk for the buyer as well as the seller in many cases. Sellers will often drop problem machines in the auction in order to “wash their hands” of it. Consequently, buyers don’t go to the auction to pay a premium because of the increased risk. Simple!`,
      link: "",
      icon: Question,
    },
    {
      videoType: false,
      title: `Does DeWITT clean and ready my machine?`,
      description: `Yes, we can for a reasonable service fee. We suggest that you clean and service your machine, just the way you might like to buy it. This will assure the quickest sale at the highest possible price.`,
      link: "",
      icon: Question,
    },
  ];

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
        <div className="pt-20">
          DeWITT Equipment Remarketing is committed to helping you get the best
          return on your investment in equipment. Below you'll find answers to
          the most common questions asked by our customers.
        </div>
        <div className="py-10">
          {AccordionData?.map((item, index) => {
            return <AccordionView data={item} />;
          })}
        </div>
      </div>
      <div></div>
    </>
  );
};
export default FaqList;
