import getApi from '../api/getApi'
import * as url from '../api/url'

// Post Contact us Api
export const getTestimonial = () => {
    return new Promise(async (resolve, reject) => {
      return getApi
        .get(url.GET_TESTIMONIAL)
        .then(response => {
          if (response) {
            resolve(response)
          }
        })
        .catch(err => {
          reject(err.response)
        })
    })
  }
  