import React, { useEffect, useState } from "react";
import CustomButton from "../CommonComponent/customButton";
import GoogleImg from "../../assets/images/Icon/GoogleImg.png";
import { useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import DefaultUserImg from "../../assets/images/default_user_icone_gray.png";
import "react-multi-carousel/lib/styles.css";
import { getTestimonial } from "../../services/testimonialSummary";
import LeftArrowImg from "../../assets/svg/left-arrow.svg";
import RightArrowImg from "../../assets/svg/right-arrow.svg";
const Testimonial = ({ data, Home }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [testimonialData, setTestimonialData] = useState([]);
  const history = useHistory();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
      <div className="carousel-button-group">
        <button
          aria-label="Go to previous slide"
          className={
            "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
          }
          onClick={() => previous()}
        >
          <img src={LeftArrowImg} alt="" />
        </button>
        <button
          aria-label="Go to next slide"
          className={
            "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
          }
          onClick={() => next()}
        >
          <img src={RightArrowImg} alt="" />
        </button>
      </div>
    );
  };

  const GetDetails = (value, resetForm) => {
    setIsLoading(true);
    getTestimonial()
      .then((res) => {
        setTestimonialData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetDetails();
  }, []);
  return (
    <React.Fragment>
      <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
        <div className="p-10">
          <label className="text-5xl flex justify-center">Testimonials</label>
          <p className="flex justify-center py-5 px-20 text-center">
            At DeWitt Equipment, we are very proud to offer a unique remarketing
            service not being replicated in the heavy equipment industry.
            Because we keep overhead low and are keenly attuned to market
            pricing, we are able to provide our consignment sellers and
            equipment buyers value, not offered by dealers or auction houses.
          </p>
          <label className="flex justify-center">
            “No machine is too big or too small”.
          </label>
          <label className="flex justify-center m-4">
            Just ask some of our customers:
          </label>
          {isLoading ? (
            <div
              className=" flex justify-center items-center"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "30px",
                height: "60vh",
              }}
            >
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          ) : Home ? (
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={10000}
              itemClass="image-item"
              containerClass="carousel-container"
              arrows={false}
              customButtonGroup={<ButtonGroup />}
            >
              {testimonialData?.map((item, index) => {
                return (
                  item?.Active ?(
                    <div className="mx-2 rounded overflow-hidden shadow-xl p-5 h-full">
                      <img
                        src={item?.TestimonialImageUrl ?? DefaultUserImg}
                        className="w-full h-32 object-contain"
                        alt="Sunset in the mountains"
                      />
                      <div className="px-6 py-4">
                        <div className="font-bold text-xl mb-2 flex justify-center">
                          {item?.Name}
                        </div>
                        <div className="mb-2 flex justify-center">
                          {item?.Title}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.TestimonialText,
                          }}
                        ></div>
                      </div>
                    </div>
                  ):null
                );
              })}
            </Carousel>
          ) : (
            <div className="flex justify-center">
              <div className="flex grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
                {testimonialData?.map((item, index) => {
                  return (
                    item?.Active ? (
                      <div className="max-w-sm rounded overflow-hidden shadow-xl w-full p-5">
                        <img
                          src={item?.TestimonialImageUrl ?? DefaultUserImg}
                          className="w-full h-32 object-contain"
                          alt="Sunset in the mountains"
                        />
                        <div className="px-6 py-4">
                          <div className="font-bold text-xl mb-2 flex justify-center">
                            {item?.Name}
                          </div>
                          <div className="mb-2 flex justify-center">
                            {item?.Title}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.TestimonialText,
                            }}
                          ></div>
                        </div>
                      </div>
                    ):null
                  );
                })}
              </div>
            </div>
          )}
          {/*  */}

          <div className="flex justify-center m-20">
            {Home ? (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded "
                onClick={() => {
                  history.push({
                    pathname: `/testimonials`,
                  });
                }}
              >
                Read More
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="flex justify-center">
            <label className="text-[50px]">Satisfied Customer?</label>
          </div>
          <div className="flex justify-center">
            <a
              href="https://g.page/r/CbbgLp34oPd9EAg/review"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomButton title="Post a Google Review" icon={GoogleImg} />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonial;
