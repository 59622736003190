import React from "react";
import "../../../styles/about_us.css"
import FaqList from "../../../components/About/FAQ";
const FAQ =()=>{
    return(
        <>
            <React.Fragment>
            <div className="FAQ_bg">
                <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
                    <div className="py-20">
                    <label className="font-semibold text-5xl text-white p-10">
                        {" "}
                        FAQ/Videos
                    </label>
                    </div>
                </div>
            </div>
            <FaqList/>
            </React.Fragment>
        </>
    )
}
export default FAQ;