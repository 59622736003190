import React from "react";
import InfoIMG from "../../assets/images/Info/Info.jpg";
const Info = () => {
  return (
    <React.Fragment>
      <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44 flex justify-center">
        <div className="py-10">
        <img
          className="w-auto h-auto rounded-md transition"
          src={InfoIMG}
          alt=""
        />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Info;
