/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";

import "../../styles/sell_machine.css";
import { Button } from "reactstrap";
import ImageGallery from "react-image-gallery";
import { Tooltip as ReactTooltip } from "react-tooltip";
import MachineCard from "../../components/MachineCard";
import {
  getMachineDetailsByID,
  postMachinesList,
  postSaveAdvertiseVisitLogs,
} from "../../services/machineService";
import { currencyFormat, getUniqueListBy } from "../../utils/helper";
import "../../styles/detail.css";
import { useHistory } from "react-router-dom";
import pdfFile from "../../assets/images/Icon/pdf-file.svg";
import gmailIcon from "../../assets/images/Icon/gmail.png";
import infoIcon from "../../assets/svg/info-icon-svgrepo-com.svg";
import TandemImg from "../../assets/images/tandem_img.png";
import { publicIp } from "public-ip";
import { browserName, isMobile } from "react-device-detect";
import "react-tooltip/dist/react-tooltip.css";
import axios from "axios";

const Details = (props) => {
  const [ip, setIP] = useState("");
  const [currentVideo, setCurrentVideo] = useState("");
  const [machineId, setMachineId] = useState(null);
  const [relatedPostData, setRelatedPostData] = useState(null);
  const [carouselImg, setCarouselImg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [images, setImages] = useState();

  const [detailsPageInfo, setDetailsPageInfo] = useState({
    PublicIp: "",
    PageEntryTime: new Date(),
    PageExitTime: "",
  });

  const history = useHistory();
  const MachineData = props.history.location.state?.id ?? null;

  if (
    !machineId &&
    MachineData &&
    MachineData?.category &&
    MachineData.category?.machine_type_id
  ) {
    setMachineId(MachineData?.category?.machine_type_id);
  }

  useEffect(() => {
    if (window.location.href.split("userID=")?.[1]) {
      localStorage.setItem(
        "UserID",
        window.location.href.split("userID=")?.[1]
      );
    }
    if (props?.match?.params?.id) {
      getMachineDetails();
    }
    //passing getData method to the lifecycle method
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.videos?.length) {
      let vidUrl;
      if (data?.videos[0].url.includes("v=")) {
        vidUrl = data?.videos[0].url.split("v=").pop();
      } else {
        vidUrl = data?.videos[0].url.split("/").pop();
      }
      setCurrentVideo(vidUrl);
    }
    if (data) {
      GetRelatedPostsList(data?.category?.machine_type_id);
      let imageData = data?.images?.map(({ url: image, ...rest }) => ({
        image,
        ...rest,
      }));
      setImages(imageData);
    }
  }, [data]);

  useEffect(() => {
    if (images) {
      let uniqueData = getUniqueListBy(images, "id");
      let shortImag = uniqueData?.sort(function (a, b) {
        return a?.order - b?.order;
      });
      let allImg = shortImag;
      shortImag?.map((item, index) => {
        allImg[index] = {
          ...allImg[index],
          original: item?.image,
          thumbnail: item?.image,
        };
      });
      setCarouselImg(allImg);
    }
  }, [images]);

  useEffect(() => {
    if (data.length !== 0 && ip) {
      GetAssetId();
    }
  }, [data, ip]);

  useEffect(() => {
    return () => {
      if (data.length !== 0 && ip) {
        const total =
          Date.parse(new Date()) - Date.parse(detailsPageInfo.PageEntryTime);
        const seconds = Math.floor((total / 1000) % 60);
        SaveAdvertiseVisitLogs(seconds);
        // Your code you want to run on unmount.
      }
    };
  }, [data, ip]);

  useEffect(() => {
    if (data.length !== 0 && ip) {
      setlocalStorageData();
    }
  }, [data, ip]);

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };
  const getMachineDetails = () => {
    setIsLoading(true);
    const decode = decodeURIComponent(props?.match?.params?.id);
    let id = decode.split("-");
    getMachineDetailsByID(id[0])
      .then((res) => {
        setData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const GetRelatedPostsList = (ID) => {
    setIsLoading(true);
    let payload = {
      AssetTypeId: ID,
      CategoryId: data?.categorization?.category?.id,
      PageSize: 3,
      PageNumber: 1,
      IsLatestFirst: true,
    };
    postMachinesList(payload)
      .then((res) => {
        let filterMachinesList = res?.data?.machines?.filter(
          (item) => item?.assetAdvertisementId !== data?.assetAdvertisementId
        );
        setRelatedPostData(filterMachinesList);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const handleOnPressCall = () => {
    if (data?.dealer?.contact_user?.phone) {
      return `callto:${data?.dealer?.contact_user?.phone}`;
    } else if (data?.dealer?.contact_user?.email) {
      return `mailto:${data?.dealer?.contact_user?.email}`;
    } else {
      return "javascript:void(0)";
    }
  };
  const handelCategorizationBreadcrumb = (e) => {
    const categorizatioList = e;
    if (data?.category?.machine_type_id === 1) {
      history.push({
        pathname: "/attachments-components",
        state: { categorizatioList: categorizatioList },
      });
    } else {
      history.push({
        pathname: `/machines`,
        state: { categorizatioList: categorizatioList },
      });
    }
  };
  const mailTemplate = () => {
    let mail = `mailto:?subject=Machine Details &body=%0D%0APlease find the equipment details as below%0D%0A
                    %0D%0ATitle%20:%20${data?.year > 0 && data?.year}${
      data?.manufacturer?.name && data?.manufacturer.name
    }${data?.model?.number && data?.model?.number}
                    %0D%0APrice%20:%20${
                      data?.advertised_price?.currency?.symbol
                    }${data?.advertised_price?.fractional}
                    %0D%0AManufacturer%20:%20${
                      data ? data?.manufacturer?.name : "-"
                    }
                    %0D%0AModel%20:%20${data ? data?.model?.number : "-"}
                    %0D%0AYear%20:%20 ${data ? data?.year : "-"}
                    %0D%0ACondition%20:%20${
                      data?.meter_reading ? "Used" : "New"
                    }
                    %0D%0AHours%20:%20${data ? data?.meter_reading : "-"}
                    %0D%0ALink%20:%20${window.location.href}
                    `;
    return mail;
  };
  const SaveAdvertiseVisitLogs = async (seconds) => {
    if (window.location.hostname === "dewittequipment.com") {
      let payload = {
        AssetAdvertisementId: data.assetAdvertisementId,
        AssetId: data.id,
        IPAddress: ip,
        VisitLengthSeconds: seconds,
        BrowserName: browserName,
        DeviceType: isMobile ? "Mobile" : "Web",
        PreviousPageURL: window.location.href,
        Source: window.location.hostname,
        AdVisitId: localStorage.getItem("AdVisitId"),
      };
      if (localStorage.getItem("UserID")) {
        payload = { ...payload, ContactUuid: localStorage.getItem("UserID") };
      }
      await postSaveAdvertiseVisitLogs(payload)
        .then((res) => {
          localStorage.removeItem("machineDetailsShow");
          localStorage.removeItem("machineDetails");
          localStorage.removeItem("machineDetailsCurrontURL");
          localStorage.removeItem("AdVisitId");
        })
        .catch((err) => console.log(err));
    }
  };
  const GetAssetId = async () => {
    if (window.location.hostname === "dewittequipment.com") {
      let payload = {
        AssetAdvertisementId: data.assetAdvertisementId,
        AssetId: data.id,
        IPAddress: ip,
        BrowserName: browserName,
        DeviceType: isMobile ? "Mobile" : "Web",
        PreviousPageURL: window.location.href,
        Source: window.location.hostname,
      };
      if (localStorage.getItem("UserID")) {
        payload = { ...payload, ContactUuid: localStorage.getItem("UserID") };
      }
      await postSaveAdvertiseVisitLogs(payload)
        .then((res) => {
          localStorage.setItem("AdVisitId", res?.data?.AdVisitId);
        })
        .catch((err) => console.log(err));
    }
  };
  const setlocalStorageData = async () => {
    let detail = {
      AssetAdvertisementId: data.assetAdvertisementId,
      AssetId: data.id,
      VisitLengthSeconds: new Date(),
      IPAddress: ip,
      BrowserName: browserName,
      DeviceType: isMobile ? "Mobile" : "Web",
      PreviousPageURL: window.location.href,
      Source: window.location.hostname,
    };

    localStorage.setItem("machineDetailsShow", true);
    localStorage.setItem("machineDetails", JSON.stringify(detail));
    localStorage.setItem(
      "machineDetailsCurrontURL",
      new URL(window.location.href)
    );
  };

  // useEffect(() => {
  //   const onBeforeUnload = (ev) => {
  //     ev.returnValue = "Anything you wanna put here!";
  //     return "Anything here as well, doesn't matter!";
  //   };

  //   window.addEventListener("beforeunload", onBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", onBeforeUnload);
  //   };
  // }, []);

  // useBeforeunload(async (event) => {
  //   event.preventDefault();
  //   const total =
  //     Date.parse(new Date()) - Date.parse(detailsPageInfo.PageEntryTime);
  //   const seconds = Math.floor((total / 1000) % 60);
  //   await SaveAdvertiseVisitLogs(seconds);
  // });
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className=" flex justify-center items-center"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "30px",
            height: "60vh",
          }}
        >
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      ) : (
        <div class="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44 mt-10">
          <div className="my-10 text-[#65bec2] cursor-pointer flex flex-wrap">
            {data?.categorization?.asset_type?.name && (
              <button
                className="cursor-pointer"
                onClick={() => {
                  handelCategorizationBreadcrumb([
                    data?.categorization?.asset_type?.id,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                  ]);
                }}
              >
                {data?.categorization?.asset_type?.name}
              </button>
            )}

            {data?.categorization?.category?.name && (
              <button
                className="cursor-pointer"
                onClick={() => {
                  handelCategorizationBreadcrumb([
                    data?.categorization?.asset_type?.id,
                    data?.categorization?.category?.id,
                    null,
                    null,
                    null,
                    null,
                    data?.categorization?.category?.name,
                  ]);
                }}
              >
                {" // "}
                {data?.categorization?.category?.name}
              </button>
            )}

            {data?.categorization?.tier1?.name && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  handelCategorizationBreadcrumb([
                    data?.categorization?.asset_type?.id,
                    data?.categorization?.category?.id,
                    data?.categorization?.tier1?.id,
                    null,
                    null,
                    null,
                    data?.categorization?.category?.name,
                  ]);
                }}
              >
                {" // "}
                {data?.categorization?.tier1?.name}
              </div>
            )}

            {data?.categorization?.tier2?.name && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  handelCategorizationBreadcrumb([
                    data?.categorization?.asset_type?.id,
                    data?.categorization?.category?.id,
                    data?.categorization?.tier1?.id,
                    data?.categorization?.tier2?.id,
                    null,
                    null,
                    data?.categorization?.category?.name,
                  ]);
                }}
              >
                {" // "}
                {data?.categorization?.tier2?.name}
              </div>
            )}

            {data?.categorization?.tier3?.name && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  handelCategorizationBreadcrumb([
                    data?.categorization?.asset_type?.id,
                    data?.categorization?.category?.id,
                    data?.categorization?.tier1?.id,
                    data?.categorization?.tier2?.id,
                    data?.categorization?.tier3?.id,
                    null,
                    data?.categorization?.category?.name,
                  ]);
                }}
              >
                {" // "}
                {data?.categorization?.tier3?.name}
              </div>
            )}

            {data?.categorization?.tier4?.name && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  handelCategorizationBreadcrumb([
                    data?.categorization?.asset_type?.id,
                    data?.categorization?.category?.id,
                    data?.categorization?.tier1?.id,
                    data?.categorization?.tier2?.id,
                    data?.categorization?.tier3?.id,
                    data?.categorization?.tier4?.id,
                    data?.categorization?.category?.name,
                  ]);
                }}
              >
                {" // "}
                {data?.categorization?.tier4?.name}
              </div>
            )}
          </div>
          <div>
            {carouselImg?.length > 0 ? (
              <ImageGallery
                items={carouselImg}
                infinite={true}
                showPlayButton
                autoPlay={true}
                useBrowserFullscreen={false}
                originalWidth={CarouselStyle.width}
                originalHeight={CarouselStyle.height}
                slideInterval={5000}
              />
            ) : (
              <div className="text-center flex justify-center">
                <img src={data?.primary_image?.url} alt="" />
              </div>
            )}
          </div>
          <div className="flex justify-center flex-row px-1 sm:px-1 md:px-4 lg:px-20s py-10 grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
            <div>
              <label className="text-2xl">Specifications</label>
              <div className="mt-4 grid grid-cols-2">
                <label className="font-bold">Title: </label>
                <label>
                  {data?.year > 0 && data?.year}{" "}
                  {data?.manufacturer?.name && data?.manufacturer.name}{" "}
                  {data?.model?.number && data?.model?.number}
                </label>
              </div>
              <div className="mt-4 grid grid-cols-2">
                <label className="font-bold">Price: </label>
                <label>
                  {data?.advertised_price?.fractional != 0 ? (
                    <>
                      {data?.advertised_price?.currency?.symbol}{" "}
                      {currencyFormat(
                        Number(data?.advertised_price?.fractional)
                      )}
                    </>
                  ) : (
                    <a
                      href={handleOnPressCall()}
                      className="bg-cyan-600 text-white py-1 px-2 text-sm rounded disable:hover  "
                    >
                      Call for Pricing
                    </a>
                  )}
                </label>
              </div>
              <div className="mt-4 grid grid-cols-2">
                <label className="font-bold">Manufacturer: </label>
                <label>{data ? data?.manufacturer?.name : "-"}</label>
              </div>
              <div className="mt-2 grid grid-cols-2">
                <label className="font-bold">Model: </label>
                <label>{data ? data?.model?.number : "-"}</label>
              </div>
              <div className="mt-2 grid grid-cols-2">
                <label className="font-bold">Year: </label>
                <label>{data ? data?.year : "-"}</label>
              </div>
              <div className="mt-2 grid grid-cols-2">
                <label className="font-bold">Condition: </label>
                {data?.meter_reading ? <label>Used</label> : <label>New</label>}
              </div>
              <div className="mt-2 grid grid-cols-2">
                <label className="font-bold">Hours: </label>
                <label>{data ? data?.meter_reading : "-"}</label>
              </div>
              <div className="mt-5">
                <label className="text-2xl">Description </label>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.machine_description_html,
                }}
                className="mt-5 htmlTable"
              ></div>
            </div>

            <div className="text-left sm:text-left md:text-left lg:text-right">
              <label className=" text-2xl">Documents</label>
              <div className="mt-2">
                {data?.documents?.map((document, key) => {
                  return (
                    <div className="flex flex-row    sm:flex-row md:justify-start lg:justify-end">
                      <a
                        href={document?.url}
                        download
                        style={{ color: "#2200CC" }}
                      >
                        {document?.name && (
                          <div className="flex flex-col justify-end  items-center sm:justify-start md:justify-start lg:justify-end">
                            <ReactTooltip />
                            <img
                              data-tip={document?.name}
                              src={pdfFile}
                              width={"60px"}
                              className="mr-2"
                              alt=""
                            />
                            <span>{document?.name}</span>
                          </div>
                        )}
                      </a>
                    </div>
                  );
                })}
                {data && data?.documents && data?.documents?.length <= 0 && (
                  <span>*Documents not available</span>
                )}
              </div>
              {data?.videos?.length && currentVideo ? (
                <div className="flex justify-start sm:justify-start md:justify-start lg:justify-end mt-10">
                  <iframe
                    src={`https://www.youtube.com/embed/${currentVideo}`}
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen="allowfullscreen"
                    title="video"
                    className="mt-2 border-2 p-6 border-black rounded-md text-right"
                  />
                </div>
              ) : null}
              <div className="flex justify-start sm:justify-start md:justify-start lg:justify-end">
                {data?.videos?.map((videos, key) => {
                  return (
                    <div>
                      <Button
                        className={`bg-black hover:bg-black-700 text-white m-2 mx-[0px] font-bold py-2 px-4 rounded ${
                          key === 0 ? "mr-[58px] " : ""
                        }`}
                        onClick={() => {
                          const vidUrl = videos?.url?.split("v=").pop();
                          setCurrentVideo(vidUrl);
                        }}
                      >
                        {videos?.name}
                      </Button>
                    </div>
                  );
                })}
              </div>
              <button
                className="bg-blue-500 hover:bg-yellow-600  text-white cursor-pointer font-bold text-2xl py-4 px-3 text-center  rounded mt-8"
                onClick={() => {
                  const encodeURICom = encodeURIComponent(
                    `${data.assetAdvertisementId}-${data?.year || ""}-${
                      data?.manufacturer?.name
                    }-${data?.model?.number}`
                  );
                  history.push({
                    pathname: `/contact-us/${encodeURICom}`,
                    state: { id: data },
                  });
                }}
              >
                Get More informaiton
              </button>
              <div className="flex justify-end mt-5 items-start flex-col sm:items-start md:items-start lg:items-end">
                <div className="flex items-end flex-col ">
                  <div className="mb-2">Share</div>
                  <div className="ml-2">
                    <a href={mailTemplate()} className="text-[#E8A600]">
                      <img
                        src={gmailIcon}
                        alt=""
                        className="w-[50px] cursor-pointer"
                      />
                    </a>
                  </div>
                </div>

                {/* <a
                  href={
                    "https://community.mycreditportal.ca/s/customer-application-form?oid=0055X000000WnXFQA0"
                  }
                  target="_blank"
                  className="w-[300px] mt-10 cursor-pointer p-[10px] border border-[#000] rounded-[10px]"
                >
                  <img src={TandemImg} alt="" />
                </a> */}

                <div
                  className="w-[300px] mt-10 cursor-pointer p-[10px] border border-[#000] rounded-[10px]"
                  id="app-title"
                >
                  <div className="flex items-center justify-between text-[20px]">
                    <img src={TandemImg} alt="" className="w-[166px]" />

                    <a
                      href={
                        "https://community.mycreditportal.ca/s/customer-application-form?oid=0055X000000WnXFQA0"
                      }
                      target="_blank"
                    >
                      <button className="bg-[#E09900] hover:bg-[#E09900] text-white font-bold py-1 px-2 rounded">
                        Apply
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="py-10">
              <label className="text-3xl">Related Posts</label>
              <div className="flex flex-row grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
                {relatedPostData &&
                  relatedPostData?.length > 0 &&
                  relatedPostData?.map((machineInfo) => (
                    <MachineCard machineData={machineInfo} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const CarouselStyle = {
  width: "1080px",
  height: "600px",
  thumbnailWidth: "100px",
  captionStyle: {
    display: "none",
  },
  layout: {
    textAlign: "center",
    maxWidth: "1080px",
    maxHeight: "100%",
    minHeight: "100%",
    margin: "50px auto 90px auto",
  },
};
export default Details;
