import Logo from "../../../assets/images/Attachments/atttachmentsLogo.png";
import copyIcon from "../../../assets/images/Icon/copyIcon.png";
import CustomButton from "../../CommonComponent/customButton";
import AttachmentModel from "./AttachmentModel";
import { useState } from "react";
const AttachmentsDetails = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <AttachmentModel modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <div className="py-10 container mx-auto  px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
        <div className="flex justify-end">
          <img
            className="w-[250px] h-[200px] rounded-md transition"
            src={Logo}
            alt=""
          />
        </div>
        <div className="text-[24px] my-10">
          DeWitt’s inventory and network of contractors and dealers is a great
          source of machine attachments and major components. These items
          represented a small and growing selection of items. If you need a
          component for any make and model of machine, or a specific attachment
          for a job, please fill out the contact form and will find the items
          for you.
        </div>
        <div className="text-[24px]">
          If you have some attachments you don’t need anymore, send us the
          details and we can present them here.
        </div>
        <div className="my-20 flex justify-center">
          <div
           onClick={()=>setIsOpen(!modalIsOpen)}
          >
            {" "}
            <CustomButton
              title="Request an Attachment or Component"
              icon={copyIcon}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AttachmentsDetails;
