import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/Attachments/atttachmentsLogo.png";
import Machine1 from "../../assets/images/Machines/machine-5.png";
import Machine2 from "../../assets/images/Machines/machine-4.png";
import Machine3 from "../../assets/images/Machines/machine-1.png";
import "../../styles/sell_machine.css";
import { postMachinesList } from "../../services/machineService";
import { currencyFormat } from "../../utils/helper";
const DetailCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [machinesList, setMachinesList] = useState([]);
  const history = useHistory();
  const GetRecentMachinesList = () => {
    setIsLoading(true);
    let payload = {
      PageSize: 3,
      PageNumber: 1,
      IsLatestFirst: true,
    };
    postMachinesList(payload)
      .then((res) => {
        setMachinesList(res?.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
    setIsLoading(false);
  };

  useEffect(() => {
    GetRecentMachinesList();
  }, []);
  return (
    <React.Fragment>
      <div className="detail-card-bg p-5 relative">
        <div className="flex justify-end absolute top-[-50px] right-0">
          <img
            className="w-[200px] h-[160px] rounded-md transition"
            src={Logo}
            alt=""
          />
        </div>

        {isLoading ? (
          <div
            className=" flex justify-center items-center"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "30px",
              height: "40vh",
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        ) : (
          <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44 flex justify-center">
            <div className="flex justify-center grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
              {machinesList?.machines?.map((item, index) => {
                return (
                  <div className="card bg-[#ffffff] w-full lg:w-72 2xl:w-80 h-full rounded-xl p-6 space-y-4">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const encodeURICom = encodeURIComponent(
                          `${item.assetAdvertisementId}-${item?.year}-${item?.manufacturer?.name}-${item?.model?.number}`
                        );
                        history.push({
                          pathname: `/detail/${encodeURICom}`,
                          state: { id: item },
                        });
                      }}
                    >
                      <img
                        className="w-full h-64 rounded-md transition hover:bg-cyan-300 object-cover"
                        src={
                          item && item?.primary_image && item.primary_image.url
                        }
                        alt=""
                      />
                    </div>
                    <div id="description" className="space-y-4">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          const encodeURICom = encodeURIComponent(
                            `${item.assetAdvertisementId}-${item?.year}-${item?.manufacturer?.name}-${item?.model?.number}`
                          );
                          history.push({
                            pathname: `/detail/${encodeURICom}`,
                            state: { id: item },
                          });
                        }}
                      >
                        <h2 className="text-cyan-300 font-semibold text-xl transition hover:text-cyan-500">
                          {/* {item?.primary_image?.name} */}
                          {item?.year > 0 && item.year}{" "}
                          {item?.manufacturer?.name && item.manufacturer.name}{" "}
                          {item?.model?.number && item.model.number}
                        </h2>
                      </div>

                      {item?.advertised_price?.fractional != 0 ? (
                        <p className="text-yellow-500 text-lg select-none ">
                          {item?.advertised_price?.currency?.symbol}{" "}
                          {currencyFormat(
                            Number(item?.advertised_price?.fractional)
                          )}
                        </p>
                      ) : (
                        <button className="bg-cyan-600 text-white py-1 px-2 text-sm rounded ">
                          Call for Pricing
                        </button>
                      )}

                      <p className="text-black-500 text-lg select-none">
                        {item?.location ? item.location : null}
                      </p>
                      {item?.location.trim() === "" && (
                        <>
                        <p className="text-black-500 text-lg select-none h-[56px]"></p>
                        </>
                      )}

                      <div className="flex items-center  font-semibold text-sm  pb-6">
                        <button
                          className="bg-yellow-500 hover:bg-yellow-600  text-white font-bold py-2 px-4 rounded"
                          onClick={() => {
                            const encodeURICom = encodeURIComponent(
                              `${item.assetAdvertisementId}-${item?.year}-${item?.manufacturer?.name}-${item?.model?.number}`
                            );
                            history.push({
                              pathname: `/contact-us/${encodeURICom}`,
                              state: { id: item },
                            });
                          }}
                        >
                          Contact Us
                        </button>
                        <a href="javascript:void(0);">
                          <button
                            className="bg-black hover:bg-black-700 text-white m-2 font-bold py-2 px-4 rounded"
                            onClick={() => {
                              const encodeURICom = encodeURIComponent(
                                `${item.assetAdvertisementId}-${item?.year}-${item?.manufacturer?.name}-${item?.model?.number}`
                              );
                              history.push({
                                pathname: `/detail/${encodeURICom}`,
                                state: { id: item },
                              });
                            }}
                          >
                            Details
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default DetailCard;
