import React, { useEffect } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { userRoutes } from "./routes/allRoutes";

import Authmiddleware from "./routes/middleware/Authmiddleware";
import ReactGA from "react-ga";
import { postSaveAdvertiseVisitLogs } from "./services/machineService";

const App = () => {
  const setGA = () => {
    ReactGA.initialize("UA-142196719-1");
    ReactGA.pageview("Init page view");
  };

  const SaveAdvertiseVisitLogs = async () => {
    if (window.location.hostname === "dewittequipment.com") {
      let payload = JSON.parse(localStorage.getItem("machineDetails"));
      const total =
        Date.parse(new Date()) - Date.parse(payload.VisitLengthSeconds);
      const seconds = Math.floor((total / 1000) % 60);

      payload = {
        ...payload,
        VisitLengthSeconds: seconds,
        AdVisitId: localStorage.getItem("AdVisitId"),
      };

      if (localStorage.getItem("UserID")) {
        payload = { ...payload, ContactUuid: localStorage.getItem("UserID") };
      }
      await postSaveAdvertiseVisitLogs(payload)
        .then((res) => {
          localStorage.removeItem("machineDetailsShow");
          localStorage.removeItem("machineDetails");
          localStorage.removeItem("machineDetailsCurrontURL");
          localStorage.removeItem("AdVisitId");
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    setGA();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("machineDetailsShow")) {
      SaveAdvertiseVisitLogs();
    }
  }, []);
  return (
    // <React.Fragment>
    <Router forceRefresh={true}>
      <Switch>
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            exact
          />
        ))}
      </Switch>
    </Router>
    // </React.Fragment>
  );
};

export default App;
