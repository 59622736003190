import EmployeeDetailsList from "./EmployeeDetailsList";
const AboutPage = () => {
  return (
    <>
      <div className="FAQ_bg">
        <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
          <div className="py-20">
            <label className="font-semibold text-5xl text-white p-10">
              {" "}
              About Us
            </label>
          </div>
        </div>
      </div>

      <EmployeeDetailsList />

      <div className="container mx-auto pb-20 px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
        <div className="text-center text-[35px] pt-[50px]">
          About DeWitt Equipment Company Inc.
        </div>

        <div className="text-start py-[10px] text-[22px]">
          "Most contractors continue to be <b className="italic">heavily</b>{" "}
          ‘short changed’ when using the auction or OEM dealer trade channels
          for disposal of their equipment, while buyers pay more than necessary
          through the traditional dealer channels"
        </div>

        <div className="text-start py-[10px] text-[22px]">
          <b className="italic">
            DeWitt Equipment Remarking solves this problem using deep industry
            experience combined with current technology and low overhead to
            benefit &nbsp;<u>both seller and buyer.</u>
          </b>
        </div>

        <div className="text-start py-[10px] text-[22px]">
            DeWitt Equipment Remarketing is a Canadian company located in Ontario Canada. DeWitt works with Heavy and Compact Equipment end users to help them achieve the highest possible return when disposing of equipment assets. We currently enjoy loyal customer relationships throughout our base in Ontario while maintaining customer relationships across Canada, in the United States, Latin America, and Europe.
        </div>

        <div className="flex justify-center mt-[20px]">
          <ul class="list-disc">
            <li class="text-[30px]">Road Building</li>
            <li class="text-[30px]">Aggregate Production and Shipping</li>
            <li class="text-[30px]">Excavation</li>
            <li class="text-[30px]">Earth Moving</li>
            <li class="text-[30px]">Utility Contracting</li>
            <li class="text-[30px]">Sewer and Water Main</li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default AboutPage;
