import React from "react";
import MachineList from "../../components/MachineList";
const Machines = () => {
  return (
    <React.Fragment>
      <div>
        <MachineList />
      </div>
    </React.Fragment>
  );
};
export default Machines;
