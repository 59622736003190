/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import AttachmentsList from '../../components/MachineList'
import AttachmentsDetails from '../../components/AttachmentsList/AttachmentsDetails'
import '../../styles/attachments_components.css'
import { useHistory } from 'react-router-dom'

const Attachments = () => {
  const AttachmentsDetailsRef = useRef();
  
  const history = useHistory()

  const categoryofMachineList= history?.location?.state?.categorizatioList

  useEffect(() => {
    if(categoryofMachineList){
      window.scrollTo({
        top: AttachmentsDetailsRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  },[])
  return (
    <React.Fragment>
      <div>
        <div ref={AttachmentsDetailsRef}>
        <AttachmentsDetails />
        </div>
        <AttachmentsList AttachmentsListPage={true} />
      </div>
    </React.Fragment>
  )
}
export default Attachments
