const CustomButton = ({ title, icon }) => {
  return (
    <>
      <div className="flex items-center py-[10px] text-[25px] md:text-[25px] lg:text-[30px] font-bold bg-[#f4c900] w-full md:w-full lg:w-max px-[10px] rounded-lg cursor-pointer border-2 border-[#679bb3] hover:bg-[#CFD3D7] hover:text-black text-center">
        <div className="rounded-full border-2 border-black p-[20px] mr-[10px]">
          <img src={icon} className='w-[30px]'/>
        </div>
        {title}
      </div>
    </>
  );
};
export default CustomButton;
