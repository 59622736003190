/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import SellMachine from "../../components/SellMachines";
import Info from "../../components/Info";
import DetailCard from "../../components/Details/detailsCard";
import Testimonial from "../../components/Testimonial";
import Forestell from "../../assets/images/Machines/forestell.png";
import TNT from "../../assets/images/Machines/TNT.png";
import TestimonialImg from "../../assets/images/Machines/testimonial3.png";
import { postMachinesList } from "../../services/machineService";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const history = useHistory();

  const testimonialData = [
    {
      img: Forestell,
      title: `Mason Tresnak`,
      status: `President`,
      description: `DeWitt Remarketing had our finance contracts paid out and a cheque in my hand in a couple of weeks. They kept us informed and updated on the process throughout. The business was professional and transparent. There were no games.`,
    },
    {
      img: TNT,
      title: `Todd Golow`,
      status: `President`,
      description: `We contacted Jeff at DeWitt Equipment Remarketing and he outlined their “Fair Market Value” consignment program. We consigned the machine to DeWitt and within a couple of weeks it was sold for price that could not be touched by the original dealer.`,
    },
    {
      img: TestimonialImg,
      title: `Albert Scamurra`,
      status: `President`,
      description: `We would not hesitate to recommend DeWitt Equipment Remarketing as an effective option to the auction. In the end we did enjoy a considerably higher return with a lot less risk.`,
    },
  ];

  const GetMachinesList = () => {
    setIsLoading(true);
    let payload = {
      PageSize: 6,
      PageNumber: 1,
      IsFeatured: true,
    };
    postMachinesList(payload)
      .then((res) => {
        let showImgList = [];
        res?.data?.machines?.map?.((item, index) => {
          showImgList[index] = {
            ...showImgList[index],
            image: item?.primary_image?.url,
            caption: `<div  class="bg-[#000] responsive_text_field" style="background: rgba(0,0,0,0.5); width: 90%; margin: auto; text-align:right; padding:0px 10px ">
                        ${item?.year ?? ""}&nbsp;&nbsp;&nbsp;${item?.model?.number ?? ""}&nbsp;&nbsp;&nbsp;${item?.model?.manufacturer?.name ?? ""} -  Click for Details
                      </div>`,
            assetAdvertisementId: item?.assetAdvertisementId,
            name: item?.primary_image?.name.replaceAll(" ", "-"),
          };
        });
        setImages(showImgList);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    GetMachinesList();
    if (window.location.href.split("userID=")?.[1]) {
      localStorage.setItem("UserID",window.location.href.split("userID=")?.[1])
    }
  }, []);
  return (
    <React.Fragment>
      <div>
        {isLoading ? (
          <div
            className=" flex justify-center items-center"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "30px",
              height: "40vh",
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        ) : (
          <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
            <div className="relative">
              <div className="absolute bg-[#E09900] text-white text-[30px] left-[-4%] z-[1] p-[10px] font-bold rounded-lg mt-[-30px] sm:text-[30px] md:text-[40px] sm:left-[-3%] md:left-[-2%] lg:left-[-1%] xl:left-[-2%]">
                Featured Inventory
              </div>
              <div className="relative mt-[40px] mb-20">
                {images?.length > 0 && (
                  <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    centerMode={false}
                    showStatus={false}
                    interval={5000}
                  >
                    {images?.map((item, index) => {
                      return (
                        <div
                          className="cursor-pointer relative"
                          onClick={() => {
                            const encodeURICom = encodeURIComponent(
                              `${item.assetAdvertisementId}-${item?.name}`
                            );
                            history.push({
                              pathname: `/detail/${encodeURICom}`,
                              state: { id: item },
                            });
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url("${item?.image}")`,
                              backgroundSize: "cover",
                            }}
                            className="w-full h-[80vh] bg-no-repeat bg-center flex items-end"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.caption,
                              }}
                              className="w-full mb-10 text-white"
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
        )}
        <SellMachine />

        {/* ----------------Home Page Details Part ------------------- */}
        <Info />

        {/* ----------------Home Page product DetailCard ------------------- */}
        <DetailCard />

        {/* ----------------Home Page Testimonial ------------------- */}
        <Testimonial data={testimonialData} Home={true} />
      </div>
    </React.Fragment>
  );
};

export default Home;
