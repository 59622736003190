import React from 'react'
import ReactPlayer from 'react-player'
import {Accordion} from 'responsive-react-accordion'
import './accordion.css'
import downArrow from '../../../assets/images/Icon/downArrow.png'
import upArrow from '../../../assets/images/Icon/upArrow.png'
export default function AccordionView({data}) {
  return (
    <div className="Accordion">
      <Accordion
        title={
          <div className="font-semibold marker:[color:#f4c900] flex w-full accordionTitle items-center text-[18px]">
            <img src={data?.icon} className="w-[25px] mr-[10px]" />
            {data?.title}
          </div>
        }
        openIcon={
          <img
            src={downArrow}
            className="font-semibold marker:[color:#f4c900] flex w-[25px] accordionTitle items-center text-[18px]"
          />
        }
        closeIcon={
          <img
            src={upArrow}
            className="font-semibold marker:[color:#f4c900] flex w-[25px] accordionTitle items-center text-[18px]"
          />
        }
        content={
          <div className="mt-3">
            <div className="flex flex-col md:flex-col lg:flex-row">
              {data?.videoType && (
                <div className="w-6/12 h-[200px] md:w-full lg:w-6/12 2xl:3/12 ">
                  <ReactPlayer
                    url={data.link}
                    playing={false}
                    volume={1}
                    width={'100%'}
                    height={'100%'}
                    controls={true}
                  />
                </div>
              )}
              <div className="w-full">
                <p className="text-sm leading-6 text-gray-600 text-[20px] p-[19px] text-[#2B2C28]">
                  <div dangerouslySetInnerHTML={{ __html: data?.description }} /> 
                </p>
              </div>
            </div>
          </div>
        }
        spacing={1}
      />
      {/* <div className="w-full px-0 mx-auto mt-4 space-y-2 ">
        <details className="rounded-lg">
          <summary className="font-semibold border-b-2 border-[#f4c900] marker:[color:#f4c900]">
            {data?.title}
          </summary>
          <div className="mt-3">
            <div className="flex flex-col md:flex-col lg:flex-row">
              {data?.videoType && (
                <div className="w-6/12 h-[200px] md:w-full lg:w-6/12 2xl:3/12 ">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?time_continue=2&v=2b9h_2Et7nY&feature=emb_title"
                    playing={false}
                    volume={1}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              )}
              <div className="w-full">
                <p className="text-sm leading-6 text-gray-600 text-[20px] p-[19px] text-[#2B2C28]">
                  {data?.description}
                </p>
              </div>
            </div>
          </div>
        </details>
      </div> */}
    </div>
  )
}
