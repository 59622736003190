import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import Modal from "react-modal";
import { Input } from "reactstrap";
import { Button } from "reactstrap";
import { postContactUs } from "../../../../services/contactUsService";
import CloseLogo from "../../../../assets/svg/close-circle-svgrepo-com.svg";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    zIndex: 6,
  },
};

const ErrorMessage = ({ massage }) => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <p style={{ color: "red" }}>{massage}</p>
      </div>
    </>
  );
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

const AttachmentModel = ({ modalIsOpen, setIsOpen }) => {
  let subtitle;
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentValue, setAttachmentValue] = useState("");
  function openModal() {
    setIsOpen(true);
  }

  const reCaptchaRef = useRef(null);
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmitForm = (value) => {
    const htmlForm = `
  <div style="font-size:25px">
    <div>
      <table>
        <tr style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.name ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
            value?.email ?? ""
          }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ""
    }</a></td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.phone ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.attachmentinfo ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.make ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
            value?.model ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
            value?.SerialInfo ?? ""
          }</td>
        </tr>
      </table>
    <div>
  </div>
`;
    setIsLoading(true);
    let payload = {
      HtmlContent: htmlForm,
    };
    postContactUs(payload)
      .then((res) => {
        toast.success(res?.data);
        reCaptchaRef?.current?.reset();
        setIsLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      attachmentinfo: "",
      make: "",
      model: "",
      SerialInfo: "",
      reCaptcha: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      phone: Yup.string().required("This field is required"),
      email: Yup.string().email().required("This field is required"),
      attachmentinfo: Yup.string().required("This field is required"),
      make: Yup.string().required("This field is required"),
      model: Yup.string().required("This field is required"),
      SerialInfo: Yup.string().required("This field is required"),
      reCaptcha: Yup.string().required("recaptcha is a required field"),
    }),
    onSubmit: async (values, { resetForm }) => {
      handleSubmitForm(values);
    },
  });

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="overflow-auto max-h-[90vh] w-[80vw]">
          <div className="bg-[#3b3c38] relative py-2">
            <div className="text-right absolute right-0">
              <Button
                className="bg-[#0000] text-[#e09900] font-bold px-5 py-2 rounded-md"
                onClick={closeModal}
              >
                <img src={CloseLogo} alt="" className="w-[30px]" />
              </Button>
            </div>
            <div className="text-[#f4c900] text-[36px] sm:text-[22px] md:text-[40px] px-20 m-auto text-center pb-4">
              Request an Attachment or Component
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="p-10">
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <div>
                  <div>Name</div>
                  <div>
                    <Input
                      type="text"
                      id="userfirstname"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      className="border-2 border-[#bfc3c8] rounded-md w-full p-2"
                    ></Input>
                    {formik?.touched?.name && formik?.errors?.name && (
                      <ErrorMessage massage={formik?.errors?.name} />
                    )}
                  </div>
                </div>
                <div>
                  <div>Phone Number</div>
                  <div>
                    <Input
                      type="number"
                      id="userPhoneNumber"
                      name="phone"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      className="border-2 border-[#bfc3c8] rounded-md w-full p-2"
                    ></Input>

                    {formik?.touched?.phone && formik?.errors?.phone && (
                      <ErrorMessage massage={formik?.errors?.phone} />
                    )}
                  </div>
                </div>
                <div>
                  <div>Email</div>
                  <div>
                    <Input
                      type="email"
                      id="userEmail"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      className="border-2 border-[#bfc3c8] rounded-md w-full p-2"
                    ></Input>
                    {formik?.touched?.email && formik?.errors?.email && (
                      <ErrorMessage massage={formik?.errors?.email} />
                    )}
                  </div>
                </div>
                <div>
                  <div>Attachment/Component Details</div>
                  <div>
                    <Input
                      type="text"
                      id="Attachment"
                      name="attachmentinfo"
                      onChange={formik.handleChange}
                      value={formik.values.attachmentinfo}
                      className="border-2 border-[#bfc3c8] rounded-md w-full p-2"
                    ></Input>
                    {formik?.touched?.attachmentinfo &&
                      formik?.errors?.attachmentinfo && (
                        <ErrorMessage
                          massage={formik?.errors?.attachmentinfo}
                        />
                      )}
                  </div>
                </div>
                <div>
                  <div>Make</div>
                  <div>
                    <Input
                      type="text"
                      id="Make"
                      name="make"
                      onChange={formik.handleChange}
                      value={formik.values.make}
                      className="border-2 border-[#bfc3c8] rounded-md w-full p-2"
                    ></Input>
                    {formik?.touched?.make && formik?.errors?.make && (
                      <ErrorMessage massage={formik?.errors?.make} />
                    )}
                  </div>
                </div>
                <div>
                  <div>Model</div>
                  <div>
                    <Input
                      type="text"
                      id="Model"
                      name="model"
                      onChange={formik.handleChange}
                      value={formik.values.model}
                      className="border-2 border-[#bfc3c8] rounded-md w-full p-2"
                    ></Input>
                    {formik?.touched?.model && formik?.errors?.model && (
                      <ErrorMessage massage={formik?.errors?.model} />
                    )}
                  </div>
                </div>
                <div>
                  <div>Serial info</div>
                  <div>
                    <Input
                      type="text"
                      id="userSerialinfo"
                      name="SerialInfo"
                      onChange={formik.handleChange}
                      value={formik.values.SerialInfo}
                      className="border-2 border-[#bfc3c8] rounded-md w-full p-2"
                    ></Input>
                    {formik?.touched?.SerialInfo &&
                      formik?.errors?.SerialInfo && (
                        <ErrorMessage massage={formik?.errors?.SerialInfo} />
                      )}
                  </div>
                </div>
              </div>
              <div className="mt-7 flex justify-center items-center flex-col">
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  size="normal"
                  sitekey={"6Lfb3HsiAAAAAAcGUm4Cxy4v8RVaBtbfC9WsCpYA"}
                  verifyCallback={(response) => {}}
                  onChange={(captchaCode) => {
                    formik.setFieldValue("reCaptcha", captchaCode);
                    // setFieldValue("reCaptcha", captchaCode);
                    // onReCAPTCHAChange
                  }}
                />
                {formik.touched.reCaptcha && formik.errors.reCaptcha && (
                  <div
                    style={{ fontSize: 14, textAlign: "left" }}
                    className=" mt-1 text-red-600"
                  >
                    {formik.errors.reCaptcha}
                  </div>
                )}
              </div>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`${
                    isLoading
                      ? "bg-[#1010104D] cursor-not-allowed	"
                      : "bg-blue-500"
                  } ${
                    isLoading ? "bg-[#1010104D]" : "hover:bg-yellow-600"
                  } mr-2 bg-[#f4c900] px-5 py-2 border border-black rounded-md mt-3 text-[18px]`}
                >
                  Request an Attachment or Component
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AttachmentModel;
