import React from "react";
import "../../styles/sell_machine.css";

const SellMachine = () => {
  return (
    <React.Fragment>
      <div className="break_lease_bg">
        <div className="p-20">
          <label className="font-semibold text-5xl text-gray-500 p-10">
            {" "}
            Sell My Machine
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SellMachine;
