import React, { useState, useRef } from "react";
import { Formik } from "formik";
import { Input } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postContactUs } from "../../services/contactUsService";
const ContactUS = (props) => {
    const reCaptchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const AccSchema = Yup.object().shape({
        firstname: Yup.string().required("Please Enter First Name"),
        lastname: Yup.string().required("Please Enter Last Name"),
        email: Yup.string()
            .email("Enter Valid Email Address")
            .required("Please Enter E-mail Address"),
        message: Yup.string().required("Please Enter message"),
        reCaptcha: Yup.string().required("recaptcha is a required field"),
    });

    const handleSubmitForm = (value, resetForm) => {
        const htmlForm = `
      <div style="font-size:25px">
        <div>
          <table>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
            }</a></td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
            }</td>
            </tr>
          </table>
        <div>
      </div>
    `;

        const htmlFormWidthInventory = `
    <div style="font-size:25px">
      <div>
        <table>
          <tr style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
            }</a></td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 0px 0px;color:#000;">Inventory</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.inventory ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 40px;color:#000;">Message</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
            }</td>
          </tr>
        </table>
      <div>
    </div>
  `;
        setIsLoading(true);
        let payload = {
            HtmlContent: props?.match?.params?.id ? htmlFormWidthInventory : htmlForm,
        };
        postContactUs(payload)
            .then((res) => {
                toast.success(res?.data);
                reCaptchaRef?.current?.reset();
                setIsLoading(false);

                resetForm();
            })
            .catch((err) => {
                toast.error(err?.data);
                setIsLoading(false);
            });
    };
    return (
        <>
            <React.Fragment>
                {/* <ToastContainer /> */}
                <div className="FAQ_bg">
                    <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
                        <div className="py-20">
                            <label className="font-semibold text-5xl text-white p-10">
                                {" "}
                                Contact Us
                            </label>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
                    <div className="m-[auto]">
                        <div className="text-center text-[40px] my-5 font-bold">
                            Dewitt Equipment
                        </div>
                        <div className="text-center text-[20px]">
                            1793 Line 13 Gilford, Ontario L0L1R0
                        </div>
                        <div className="text-center text-[15px] my-5 font-bold">
                            Email:
                            <b>
                                <a
                                    href="mailto:info@dewittequipment.com"
                                    className="text-[#65bec2]"
                                >
                                    {" "}
                                    info@dewittequipment.com
                                </a>
                            </b>
                        </div>
                        <div className="text-center text-[15px] my-5 font-bold">
                            Phone: 1-905-330-8656
                        </div>
                    </div>
                    <div>
                        <div className="flex w-full justify-center sm:w-full md:w-full ">
                            <div>
                                <div>
                                    <Formik
                                        initialValues={{
                                            firstname: "",
                                            lastname: "",
                                            email: "",
                                            phone: "",
                                            location: "",
                                            message: "",
                                            inventory: decodeURIComponent(props?.match?.params?.id),
                                            reCaptcha: "",
                                        }}
                                        validationSchema={AccSchema}
                                        onSubmit={(values, { resetForm }) => {
                                            handleSubmitForm(values, resetForm);
                                        }}
                                    >
                                        {({
                                            errors,
                                            touched,
                                            values,
                                            handleSubmit,
                                            handleChange,
                                            setFieldValue,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="p-7 grid grid-cols-1 md:grid-cols-2 gap-8">
                                                    <div className="mb-3 flex flex-col">
                                                        <label className="contact-input-label">
                                                            First Name
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="userfirstname"
                                                            name="firstname"
                                                            className="form-control p-1 border border-[#bfc3c8] rounded-md w-full"
                                                            value={values.firstname}
                                                            onChange={(e) => handleChange(e)}
                                                        />

                                                        {errors.firstname && touched.firstname && (
                                                            <div
                                                                style={{ fontSize: 14, textAlign: "left" }}
                                                                className=" mt-1 text-red-600"
                                                            >
                                                                {errors.firstname}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className=" mb-3 flex flex-col">
                                                        <label className="contact-input-label">
                                                            Last Name
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="userfirstname"
                                                            name="lastname"
                                                            className="form-control p-1 border border-[#bfc3c8] rounded-md w-full"
                                                            value={values.lastname}
                                                            onChange={(e) => handleChange(e)}
                                                        />

                                                        {errors.lastname && touched.lastname && (
                                                            <div
                                                                style={{ fontSize: 14, textAlign: "left" }}
                                                                className=" mt-1 text-red-600"
                                                            >
                                                                {errors.lastname}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="px-7 grid grid-cols-1 md:grid-cols-2 gap-8">
                                                    <div className="mb-3 flex flex-col">
                                                        <label className="contact-input-label">Email</label>
                                                        <Input
                                                            type="email"
                                                            id="useremail"
                                                            name="email"
                                                            className="form-control p-1 border border-[#bfc3c8] rounded-md w-full"
                                                            value={values.email}
                                                            onChange={(e) => handleChange(e)}
                                                        />

                                                        {errors.email && touched.email && (
                                                            <div
                                                                style={{ fontSize: 14, textAlign: "left" }}
                                                                className=" mt-1 text-red-600"
                                                            >
                                                                {errors.email}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className=" mb-3 flex flex-col">
                                                        <label className="contact-input-label">
                                                            Phone Number
                                                        </label>
                                                        <Input
                                                            type="number"
                                                            id="userfirstname"
                                                            name="phone"
                                                            className="form-control p-1 border border-[#bfc3c8] rounded-md w-full"
                                                            value={values.phone}
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                {props?.match?.params?.id && (
                                                    <div className=" px-7 mb-3 flex flex-col">
                                                        <label className=" contact-input-label">
                                                            Inventory
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="inventory"
                                                            name="inventory"
                                                            className="form-control p-1 border border-[#bfc3c8] rounded-md w-full"
                                                            value={values?.inventory}
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                    </div>
                                                )}
                                                <div className=" px-7 mb-3 flex flex-col">
                                                    <label className=" contact-input-label">
                                                        Location
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="location"
                                                        name="location"
                                                        className="form-control p-1 border border-[#bfc3c8] rounded-md w-full"
                                                        value={values.location}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>

                                                <div className=" px-7 mb-3 flex flex-col">
                                                    <label className=" contact-input-label">
                                                        Message
                                                    </label>
                                                    <Input
                                                        type="textarea"
                                                        id="message"
                                                        name="message"
                                                        className="form-control p-1 border border-[#bfc3c8] rounded-md w-full"
                                                        value={values.message}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                    {errors.message && touched.message && (
                                                        <div
                                                            style={{ fontSize: 14, textAlign: "left" }}
                                                            className=" mt-1 text-red-600"
                                                        >
                                                            {errors.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className=" px-7 mb-3 flex flex-col">
                                                    <ReCAPTCHA
                                                        ref={reCaptchaRef}
                                                        size="normal"
                                                        sitekey={"6Lfb3HsiAAAAAAcGUm4Cxy4v8RVaBtbfC9WsCpYA"}
                                                        onChange={(captchaCode) => {
                                                            setFieldValue("reCaptcha", captchaCode);
                                                            // onReCAPTCHAChange
                                                        }}
                                                    />
                                                    {errors.reCaptcha && touched.reCaptcha && (
                                                        <div
                                                            style={{ fontSize: 14, textAlign: "left" }}
                                                            className=" mt-1 text-red-600"
                                                        >
                                                            {errors.reCaptcha}
                                                        </div>
                                                    )}
                                                </div>
                                                <button
                                                    type="submit"
                                                    disabled={isLoading}
                                                    className={`mb-2 ml-8 ${isLoading ? "bg-[#1010104D] cursor-not-allowed	" : "bg-blue-500"} ${isLoading ? "bg-[#1010104D]" : "hover:bg-yellow-600"} text-white font-bold py-2 px-4 rounded mt-8`}>
                                                    Submit
                                                </button>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </>
    );
};
export default ContactUS;
