import React from 'react'
import {Redirect} from 'react-router-dom'

import Home from '../pages/Home'
import Machines from '../pages/Machines'
import BreakLease from '../pages/BreakLease'
import SellMachine from '../pages/SellMachine'
import Details from '../pages/Machines/detail'
import Attachments from '../pages/Attachments_Components'
import FAQ from '../pages/AboutUS/FAQ'
import Testimonials from '../pages/AboutUS/Testimonials'
import AboutPage from '../pages/AboutUS'
import ContactUS from '../pages/ContactUS'
import OldiesByGoodies from '../pages/OldiesByGoodies'
import Gallery from '../pages/Gallery'
const userRoutes = [
  {path: '/', component: Home},
  {path: '/machines', component: Machines},
  {path: '/oldies-but-goodies', component: OldiesByGoodies},
  {path: '/attachments-components', component: Attachments},
  {path: '/break-lease', component: BreakLease},
  {path: '/sell-machine', component: SellMachine},
  {path: '/detail/:id', component: Details},
  {path: '/our-videos', component: FAQ},
  {path: '/testimonials', component: Testimonials},
  {path: '/about-us', component: AboutPage},
  {path:'/contact-us/:id',component:ContactUS},
  {path:'/contact-us',component:ContactUS},
  {path:'/gallery',component:Gallery},
  {path: '/', exact: true, component: () => <Redirect to="/" />},
]

export {userRoutes}
