export const PRODURL = 'https://dealerwebservice-api.azurewebsites.net/'
export const TestURL = 'https://dealerwebservice-api-dev.azurewebsites.net/'
// ? Machine List
export const MACHINE_LIST = 'DealerWebApp/GetInventory'
export const MACHINE_DETAILS = 'DealerWebApp/GetInventoryDetailById?assetId='
export const GET_MACHINES_LIST = 'DealerWebApp/GetInventory'
export const GET_RELATED_POST =
  'DealerWebApp/GetInventoryByCategory?assetTypeId='
export const GET_MACHINES_DETAILS =
  'DealerWebApp/GetInventorySummaryById'

export const GET_CATEGORY_LIST =
  'DealerWebApp/GetTierWiseAssetCategorizationSummary'
export const POST_SAVEADVERTISE ='DealerWebApp/SaveAdvertiseVisitLogs'
export const POST_CONTACT_US = 'DealerWebApp/SendContactEmail'
export const GET_TESTIMONIAL='DealerWebApp/GetTestimonialSummary'
export const URL = PRODURL
