import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Input } from "reactstrap";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import upArrow from "../../assets/svg/up-arrow.svg";
import { postContactUs } from "../../services/contactUsService";
import "../../styles/sell_machine.css";



const Footer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const reCaptchaRef = useRef(null);
  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required("Please Enter First Name"),
    lastname: Yup.string().required("Please Enter Last Name"),
    email: Yup.string()
      .email("Enter Valid Email Address")
      .required("Please Enter E-mail Address"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });

  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
      <div style="font-size:25px">
        <div>
          <table>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.firstname ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.lastname ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
                value?.email ?? ""
              }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ""
    }</a></td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.phone ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.location ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
                value?.message ?? ""
              }</td>
            </tr>
          </table>
        <div>
      </div>
    `;
    setIsLoading(true);
    let payload = {
      HtmlContent: htmlForm,
    };
    postContactUs(payload)
      .then((res) => {
        toast.success(res?.data);
        reCaptchaRef?.current?.reset();
        setIsLoading(false);

        resetForm();
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsLoading(false);
      });
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  return (
    <React.Fragment>
      <ToastContainer />
      {showTopBtn && (
        <button
          type="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="fixed inline-block p-3 bg-black flex flex-col items-center text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-black hover:shadow-lg focus:bg-black focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5 text-[16px] capitalize "
          id="btn-back-to-top"
          onClick={goToTop}
        >
          <img src={upArrow} className="w-[16px]" alt="" />
          <div className="mt-1">Top</div>
        </button>
      )}
      <div className="container min-w-full">
        <hr className="w-full border-black border" />
        <div className="flex grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2">
          <div className="bg-slate-500 md:block bg-cotact">
            <div>
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  email: "",
                  phone: "",
                  location: "",
                  message: "",
                  reCaptcha: "",
                }}
                validationSchema={AccSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmitForm(values, resetForm);
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="p-7 grid grid-cols-1 md:grid-cols-2 gap-8">
                      <div className="mb-3 flex flex-col">
                        <label className="contact-input-label">
                          First Name
                        </label>
                        <Input
                          type="text"
                          id="userfirstname"
                          name="firstname"
                          className="form-control p-2"
                          value={values.firstname}
                          onChange={(e) => handleChange(e)}
                        />

                        {errors.firstname && touched.firstname && (
                          <div
                            style={{ fontSize: 14, textAlign: "left" }}
                            className=" mt-1 text-red-600"
                          >
                            {errors.firstname}
                          </div>
                        )}
                      </div>
                      <div className=" mb-3 flex flex-col">
                        <label className="contact-input-label">Last Name</label>
                        <Input
                          type="text"
                          id="userfirstname"
                          name="lastname"
                          className="form-control p-2"
                          value={values.lastname}
                          onChange={(e) => handleChange(e)}
                        />

                        {errors.lastname && touched.lastname && (
                          <div
                            style={{ fontSize: 14, textAlign: "left" }}
                            className=" mt-1 text-red-600"
                          >
                            {errors.lastname}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-7 grid grid-cols-1 md:grid-cols-2 gap-8">
                      <div className="mb-3 flex flex-col">
                        <label className="contact-input-label">Email</label>
                        <Input
                          type="email"
                          id="useremail"
                          name="email"
                          className="form-control p-2"
                          value={values.email}
                          onChange={(e) => handleChange(e)}
                        />

                        {errors.email && touched.email && (
                          <div
                            style={{ fontSize: 14, textAlign: "left" }}
                            className=" mt-1 text-red-600"
                          >
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <div className=" mb-3 flex flex-col">
                        <label className="contact-input-label">
                          Phone Number
                        </label>
                        <Input
                          type="number"
                          id="userfirstname"
                          name="phone"
                          className="form-control p-2"
                          value={values.phone}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className=" px-7 mb-3 flex flex-col">
                      <label className=" contact-input-label">Location</label>
                      <Input
                        type="text"
                        id="location"
                        name="location"
                        className="form-control p-2"
                        value={values.location}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    <div className=" px-7 mb-3 flex flex-col">
                      <label className=" contact-input-label">Message</label>
                      <Input
                        type="textarea"
                        id="message"
                        name="message"
                        className="form-control p-2"
                        value={values.message}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className=" px-7 mb-3 flex flex-col">
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        size="normal"
                        sitekey={"6Lfb3HsiAAAAAAcGUm4Cxy4v8RVaBtbfC9WsCpYA"}
                        verifyCallback={(response) => {}}
                        onChange={(captchaCode) => {
                          setFieldValue("reCaptcha", captchaCode);
                          // onReCAPTCHAChange
                        }}
                      />
                      {errors.reCaptcha && touched.reCaptcha && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className=" mt-1 text-red-600"
                        >
                          {errors.reCaptcha}
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className={`mb-2 ml-8 ${
                        isLoading
                          ? "bg-[#1010104D] cursor-not-allowed	"
                          : "bg-blue-500"
                      } ${
                        isLoading ? "bg-[#1010104D]" : "hover:bg-yellow-600"
                      } text-white font-bold py-2 px-4 rounded mt-8`}
                    >
                      Submit
                    </button>
                  </form>
                )}
              </Formik>
            </div>
            {/* <img
              class="h-auto bg-cover bg-center bg-no-repeat"
              src="https://source.unsplash.com/random"
            /> */}
          </div>
          <div className="mt-10">
            <div className="animate-fade-in-down mb-1 ml-10 text-4xl text-black-600 mt-2">
              Contact Us
            </div>
            <div className="animate-fade-in-down mb-8 ml-20  text-md text-black-800">
              <li>Address: 1793 Line 13 Gilford, Ontario L0L1R0</li>
              <li>
                Email:{" "}
                <span className="email-text">info@dewittequipment.com</span>
              </li>
              <li>Phone: 1-905-330-8656</li>
            </div>
          </div>
        </div>
        <div className="bg-gray-800 py-10 text-center">
          <label className="justify-center text-white ">
            Copyright Dewitt Equipment Remarketing {new Date().getFullYear()}.
            Powered by
            <a href="https://vizybilitydms.com/" className="ml-2">
              Vizybility DMS
            </a>
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Footer;
