/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo.png";
import downloadLogo from "../../assets/images/Icon/download.png";
import "./header.css";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

export default function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <header className="Header">
      <div className="container mx-auto  px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44 flex justify-between">
        <a href="/">
          <img src={Logo} className="Logo" alt="logo" />
        </a>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <nav className="Nav">
            <Link className="NavLink" to="/">
              Home
            </Link>

            <Link className="NavLink" to="/machines">
              Machines
            </Link>

            <Link className="NavLink" to="/attachments-components">
              Attachments / Components
            </Link>


            <Link className="NavLink" to="/gallery">
              Gallery
            </Link>

            <div className="dropdown">
              <button className="dropbtn">
                <Link className="NavLink" to="/about-us">
                  About Us
                </Link>
              </button>

              <div className="dropdown-content">
                <Link to="/our-videos" className="dropdown-content-link">
                  FAQ / Videos
                </Link>
                <Link to="/testimonials" className="dropdown-content-link">
                  Testimonials
                </Link>
              </div>
            </div>

            <div className="dropdown NavLink">
              <button className="dropbtn">
                <Link to="/contact-us">Contact Us</Link>
              </button>
              <div className="dropdown-content">
                <Link to="/break-lease" className="dropdown-content-link">
                  Break My Lease
                </Link>
                <Link to="/sell-machine" className="dropdown-content-link">
                  Sell My Machine
                </Link>
              </div>
            </div>
          </nav>
        </CSSTransition>
        <button onClick={toggleNav} className="Burger">
          <span className="menuBtn">
            <img
              src={downloadLogo}
              className="downloadImg"
              width="10px"
              height="10px"
              alt=""
            />
            Menu
          </span>
        </button>
      </div>
    </header>
  );
}
