import React from 'react'
import PropTypes from 'prop-types'
import {Route} from 'react-router-dom'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    // {...rest}
    path={rest.path}
    render={props => {
      return (
        <React.Fragment>
          <Header />
          <React.StrictMode>
            <Component {...props} />
          </React.StrictMode>
          <Footer />
        </React.Fragment>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
