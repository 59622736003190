import Testimonial from "../../../components/Testimonial";
import MattHawkins from "../../../assets/images/Machines/MattHawkins.png";
import Forestell from "../../../assets/images/Machines/forestell.png";
import TNT from "../../../assets/images/Machines/TNT.png";
import TestimonialImg from "../../../assets/images/Machines/testimonial3.png";
const Testimonials = () => {
  const testimonialData = [
    {
      img: MattHawkins,
      title: `Matt Hawkins`,
      status: `President`,
      description: `"We have sold 4 pieces of heavy equipment though Dewitt Equipment in the past year and we have been very happy with the prices that they have sold our equipment for, and how quickly they have sold them. Their customer service and communication has been great too. We plan to continue to use Dewitt equipment for remarketing needs."`,
    },
    {
      img: Forestell,
      title: `Mason Tresnak`,
      status: `President`,
      description: `DeWitt Remarketing had our finance contracts paid out and a cheque in my hand in a couple of weeks. They kept us informed and updated on the process throughout. The business was professional and transparent. There were no games.`,
    },
    {
      img: TNT,
      title: `Todd Golow`,
      status: `President`,
      description: `We contacted Jeff at DeWitt Equipment Remarketing and he outlined their “Fair Market Value” consignment program. We consigned the machine to DeWitt and within a couple of weeks it was sold for price that could not be touched by the original dealer.`,
    },
    {
      img: TestimonialImg,
      title: `Albert Scamurra`,
      status: `President`,
      description: `We would not hesitate to recommend DeWitt Equipment Remarketing as an effective option to the auction. In the end we did enjoy a considerably higher return with a lot less risk.`,
    },
  ];

  return (
    <>
      <Testimonial data={testimonialData} />
    </>
  );
};
export default Testimonials;
