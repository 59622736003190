import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
  getAllMachinesList,
  postMachinesList,
} from '../../services/machineService'
import '../../styles/sell_machine.css'
import MachineCard from '../MachineCard'

import InfiniteScroll from 'react-infinite-scroll-component'
const AttachmentsList = ({AttachmentsListPage}) => {
  const [isMachineData, setIsMachineData] = useState([])
  const [machinesTypeList, setMachinesTypeList] = useState([])
  const [machineTypeSelectIdCategoryName, setMachineTypeSelectIdCategoryName] =
    useState('')
  const [page, setPage] = useState(1)
  const limit = 9
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [
    assetCategorizationSummaryLoading,
    setAssetCategorizationSummaryLoading,
  ] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [assetTypeId, setAssetTypeId] = useState('')
  const [allFilterID, setAllFilterID] = useState(null)
  const [dataLoadLoading, setDataLoadLoading] = useState(true)
  const [selectCategoryofMachine, setSelectCategoryofMachine] = useState('')
  const history = useHistory()
  const [categoryofMachineList, setCategoryofMachineList] = useState(
    history?.location?.state?.categorizatioList,
  )

  useEffect(() => {
    if (
      !AttachmentsListPage &&
      machinesTypeList?.length !== 0 &&
      categoryofMachineList &&
      categoryofMachineList?.length !== 0 &&
      categoryofMachineList !== undefined
    ) {
      // ------------------ categoryofMachineList zero AssetTypeId ------------------
      // ------------------ categoryofMachineList One CategoryId --------------------
      // ------------------ categoryofMachineList two Tier1Id --------------------
      // ------------------ categoryofMachineList three Tier2Id --------------------
      // ------------------ categoryofMachineList four Tier3Id ------------------
      // ------------------ categoryofMachineList Five Tier4Id ------------------
      // ------------------ categoryofMachineList Six Category Name ------------------

      handleOnChangeTypePreviousPageDetails(
        `${categoryofMachineList[6] ?? null},${
          categoryofMachineList[0] ?? null
        },${categoryofMachineList[2] ?? null},${
          categoryofMachineList[3] ?? null
        },${categoryofMachineList[4] ?? null},${
          categoryofMachineList[1] ?? null
        }`,
      )
    } else if (
      AttachmentsListPage &&
      machinesTypeList?.length !== 0 &&
      categoryofMachineList &&
      categoryofMachineList?.length !== 0 &&
      categoryofMachineList !== undefined
    ) {
      setMachineTypeSelectIdCategoryName(categoryofMachineList[1] ?? '')
      GetMachinesList(categoryofMachineList[1] ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinesTypeList, categoryofMachineList])

  useEffect(() => {
    if (
      machinesTypeList?.length > 0 &&
      categoryofMachineList === undefined &&
      !isLoading
    ) {
      GetMachinesList()
    }
  }, [
    pageNumber,
    machinesTypeList,
    allFilterID,
    machineTypeSelectIdCategoryName,
  ])

  useEffect(() => {
    GetMachinesTypeList()
  }, [])

  const GetMachinesTypeList = val => {
    setAssetCategorizationSummaryLoading(true)
    getAllMachinesList()
      .then(res => {
        if (AttachmentsListPage) {
          const AttachmentsList = res?.data?.filter(
            item => item?.AssetTypeId === 1,
          )
          setMachinesTypeList(AttachmentsList)
        } else {
          const WithoutAttachmentsList = res?.data?.filter(
            item => item?.AssetTypeId !== 1,
          )
          setMachinesTypeList(WithoutAttachmentsList)
        }
        setAssetCategorizationSummaryLoading(false)
      })
      .catch(err => console.log(err))
  }

  const GetMachinesList = val => {
    if (isMachineData?.length === 0) {
      setIsLoading(true)
    }
    setDataLoadLoading(true)
    setCategoryofMachineList(undefined)
    let payload
    if (AttachmentsListPage) {
      if (val) {
        payload = {
          AssetTypeId: machinesTypeList[0]?.AssetTypeId,
          CategoryId: val,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: false,
        }
      } else {
        payload = {
          AssetTypeId: machinesTypeList[0]?.AssetTypeId,
          CategoryId: machineTypeSelectIdCategoryName
            ? JSON.parse(machineTypeSelectIdCategoryName)
            : machineTypeSelectIdCategoryName,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: false,
        }
      }
    } else {
      if (allFilterID) {
        payload = {
          AssetTypeId: JSON.parse(assetTypeId),
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: true,
          Tier1Id: JSON.parse(allFilterID[2]),
          Tier2Id: JSON.parse(allFilterID[3]),
          Tier3Id: JSON.parse(allFilterID[4]),
          CategoryId: JSON.parse(allFilterID[5]),
        }
      } else if (val) {
        // ------------------ Split zero CategoryName ------------------
        // ------------------ Split One AssetTypeId --------------------
        // ------------------ Split two Tier1Id --------------------
        // ------------------ Split three Tier2Id --------------------
        // ------------------ Split four Tier3Id ------------------
        // ------------------ Split five CategoryId ------------------
        const IDs = val.split(',')
        payload = {
          AssetTypeId: JSON.parse(IDs?.[1]) ?? null,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: true,
          Tier1Id: JSON.parse(IDs?.[2]) ?? null,
          Tier2Id: JSON.parse(IDs?.[3]) ?? null,
          Tier3Id: JSON.parse(IDs?.[4]) ?? null,
          CategoryId: JSON.parse(IDs?.[5]) ?? null,
        }
      } else {
        payload = {
          AssetTypeId: assetTypeId,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: true,
        }
      }
    }
    payload={...payload,IsOldiesByGoodies:false}
    postMachinesList(payload)
      .then(res => {
        const machineData = res && res.data
        setTotalRecords(machineData?.TotalRecords)
        if (parseInt(machineData?.PageNumber) == 1) {
          setIsMachineData(machineData?.machines)
          setPage(machineData?.PageNumber)
        } else {
          setIsMachineData([...isMachineData, ...machineData?.machines])
          setPage(machineData?.PageNumber)
        }
        setIsLoading(false)
        setDataLoadLoading(false)
      })
      .catch(err => console.log(err))
  }

  const handleOnChangeType = e => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    setIsMachineData([])
    setPage(1)
    if (e.target.value !== '') {
      const IDs = e.target.value.split(',')
      setSelectCategoryofMachine(IDs)
      setPageNumber(1)
      setAllFilterID(IDs)
      setMachineTypeSelectIdCategoryName(IDs[0])
      setAssetTypeId(IDs[1])
    } else {
      setSelectCategoryofMachine('')
      setAllFilterID(null)
      setMachineTypeSelectIdCategoryName('')
      setAssetTypeId('')
    }
  }

  const handleOnChangeTypePreviousPageDetails = e => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    const IDs = e.split(',')
    setSelectCategoryofMachine(IDs)
    setPageNumber(1)
    setAllFilterID(IDs)
    setMachineTypeSelectIdCategoryName(IDs[0])
    setAssetTypeId(IDs[1])
    GetMachinesList(e)
  }

  return (
    <React.Fragment>
      <div className="attachment-bg bg-fixed">
        <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
          <div className="flex justify-center  grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-row pt-10  ">
            {AttachmentsListPage ? (
              <>
                <select
                  name="cat"
                  id="category-dropdown"
                  class="border border-black"
                  onChange={e => {
                    setPageNumber(1)
                    setMachineTypeSelectIdCategoryName(e.target.value)
                  }}
                  value={machineTypeSelectIdCategoryName}
                >
                  {machinesTypeList?.map((item, index) => {
                    return item?.CategoryDetails?.length > 0 ? (
                      <>
                        <option value={''}>Select Component Type</option>
                        {item?.CategoryDetails.map(CategoryDetailsitem => {
                          return CategoryDetailsitem?.Tier1Details?.length >
                            0 ? (
                            <>
                              <option value={CategoryDetailsitem?.CategoryId}>
                                {CategoryDetailsitem?.CategoryName}
                              </option>
                              {CategoryDetailsitem?.Tier1Details?.map(
                                Tier1DetailsItem => {
                                  return Tier1DetailsItem?.Tier2Details
                                    ?.length > 0 ? (
                                    <>
                                      <option value={Tier1DetailsItem?.Tier1Id}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {Tier1DetailsItem?.Tier1Name}
                                      </option>
                                      {Tier1DetailsItem?.Tier2Details?.map(
                                        Tier2DetailsItem => {
                                          return Tier2DetailsItem?.Tier3Details
                                            ?.length > 0 ? (
                                            Tier2DetailsItem?.Tier3Details?.map(
                                              Tier3Detailsitem => {
                                                return Tier3Detailsitem
                                                  ?.Tier4Details?.length > 0 ? (
                                                  <option
                                                    value={
                                                      Tier3Detailsitem?.Tier3Id
                                                    }
                                                  >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {
                                                      Tier3Detailsitem?.Tier3Name
                                                    }
                                                  </option>
                                                ) : (
                                                  <option
                                                    value={
                                                      Tier3Detailsitem?.Tier3Id
                                                    }
                                                  >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {
                                                      Tier3Detailsitem?.Tier3Name
                                                    }
                                                  </option>
                                                )
                                              },
                                            )
                                          ) : (
                                            <option
                                              value={Tier2DetailsItem?.Tier2Id}
                                            >
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              {Tier2DetailsItem?.Tier2Name}
                                            </option>
                                          )
                                        },
                                      )}
                                    </>
                                  ) : (
                                    <option value={Tier1DetailsItem?.Tier1Id}>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {Tier1DetailsItem?.Tier1Name}
                                    </option>
                                  )
                                },
                              )}
                            </>
                          ) : (
                            <option value={CategoryDetailsitem?.CategoryId}>
                              {CategoryDetailsitem?.CategoryName}
                            </option>
                          )
                        })}
                      </>
                    ) : (
                      <option value={item?.AssetTypeId}>
                        {item?.AssetTypeName}
                      </option>
                    )
                  })}
                </select>
              </>
            ) : (
              <div className="flex flex-col">
                <label className="font-bold category_machine">
                  Select Category of machine
                </label>
                <select
                  name="cat"
                  id="category-dropdown"
                  class="border border-black"
                  value={selectCategoryofMachine}
                  onChange={handleOnChangeType}
                >
                  <option value={''}>Select All</option>
                  {machinesTypeList?.map((item, index) => {
                    return item?.CategoryDetails?.length > 0 ? (
                      <>
                        <option
                          value={`${null},${
                            item?.AssetTypeId
                          },${null},${null},${null},${null}`}
                        >
                          {item?.AssetTypeName}
                        </option>
                        {item?.CategoryDetails.map(CategoryDetailsitem => {
                          return CategoryDetailsitem?.Tier1Details?.length >
                            0 ? (
                            <>
                              <option
                                value={`${CategoryDetailsitem?.CategoryName},${
                                  item?.AssetTypeId
                                },${null},${null},${null},${
                                  CategoryDetailsitem?.CategoryId
                                }`}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {CategoryDetailsitem?.CategoryName}
                              </option>
                              {CategoryDetailsitem?.Tier1Details?.map(
                                Tier1DetailsItem => {
                                  return Tier1DetailsItem?.Tier2Details
                                    ?.length > 0 ? (
                                    <>
                                      <option
                                        value={`${
                                          CategoryDetailsitem?.CategoryName
                                        },${item?.AssetTypeId},${
                                          Tier1DetailsItem?.Tier1Id
                                        },${null},${null},${
                                          CategoryDetailsitem?.CategoryId
                                        }`}
                                      >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {Tier1DetailsItem?.Tier1Name}
                                      </option>
                                      {Tier1DetailsItem?.Tier2Details?.map(
                                        Tier2DetailsItem => {
                                          return Tier2DetailsItem?.Tier3Details
                                            ?.length > 0 ? (
                                            <>
                                              <option
                                                value={`${
                                                  CategoryDetailsitem?.CategoryName
                                                },${item?.AssetTypeId},${
                                                  Tier1DetailsItem?.Tier1Id
                                                },${
                                                  Tier2DetailsItem?.Tier2Id
                                                },${null},${
                                                  CategoryDetailsitem?.CategoryId
                                                }`}
                                              >
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                {Tier2DetailsItem?.Tier2Name}
                                              </option>
                                              {Tier2DetailsItem?.Tier3Details?.map(
                                                Tier3Detailsitem => {
                                                  return Tier3Detailsitem
                                                    ?.Tier4Details?.length >
                                                    0 ? (
                                                    <option
                                                      value={`${CategoryDetailsitem?.CategoryName},${item?.AssetTypeId},${Tier1DetailsItem?.Tier1Id},${Tier2DetailsItem?.Tier2Id},${Tier3Detailsitem?.Tier3Id},${CategoryDetailsitem?.CategoryId}`}
                                                    >
                                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      {
                                                        Tier3Detailsitem?.Tier3Name
                                                      }
                                                    </option>
                                                  ) : (
                                                    <option
                                                      value={`${CategoryDetailsitem?.CategoryName},${item?.AssetTypeId},${Tier1DetailsItem?.Tier1Id},${Tier2DetailsItem?.Tier2Id},${Tier3Detailsitem?.Tier3Id},${CategoryDetailsitem?.CategoryId}`}
                                                    >
                                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      {
                                                        Tier3Detailsitem?.Tier3Name
                                                      }
                                                    </option>
                                                  )
                                                },
                                              )}
                                            </>
                                          ) : (
                                            <option
                                              value={`${
                                                CategoryDetailsitem?.CategoryName
                                              },${item?.AssetTypeId},${
                                                Tier1DetailsItem?.Tier1Id
                                              },${
                                                Tier2DetailsItem?.Tier2Id
                                              },${null},${
                                                CategoryDetailsitem?.CategoryId
                                              }`}
                                            >
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              {Tier2DetailsItem?.Tier2Name}
                                            </option>
                                          )
                                        },
                                      )}
                                    </>
                                  ) : (
                                    <option
                                      value={`${
                                        CategoryDetailsitem?.CategoryName
                                      },${item?.AssetTypeId},${
                                        Tier1DetailsItem?.Tier1Id
                                      },${null},${null},${
                                        CategoryDetailsitem?.CategoryId
                                      }`}
                                    >
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {Tier1DetailsItem?.Tier1Name}
                                    </option>
                                  )
                                },
                              )}
                            </>
                          ) : (
                            <option
                              value={`${CategoryDetailsitem?.CategoryName},${
                                item?.AssetTypeId
                              },${null},${null},${null},${
                                CategoryDetailsitem?.CategoryId
                              }`}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {CategoryDetailsitem?.CategoryName}
                            </option>
                          )
                        })}
                      </>
                    ) : (
                      <option
                        value={`${null},${
                          item?.AssetTypeId
                        },${null},${null},${null},${null}`}
                      >
                        {item?.AssetTypeName}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>
          {isLoading || assetCategorizationSummaryLoading ? (
            // {true ? (
            <div
              className=" flex justify-center items-center"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '30px',
                height: '100vh',
              }}
            >
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          ) : isMachineData.length > 0 ? (
            <>
              <div className="pb-[20px]">
                <InfiniteScroll
                  dataLength={isMachineData.length}
                  next={() =>
                    totalRecords >= isMachineData?.length
                      ? setPageNumber(page + 1)
                      : null
                  }
                  hasMore={true}
                  loader={null}
                >
                  <div className=" flex justify-center items-center grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-row py-10">
                    {isMachineData.map((data, key) => (
                      <MachineCard machineData={data} />
                    ))}
                  </div>
                </InfiniteScroll>

                {dataLoadLoading && (
                  <div
                    className=" flex justify-center items-center"
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                className=" flex justify-center items-center"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '30px',
                  height: '80vh',
                }}
              >
                <div className="spinner-container text-[30px] text-[#fff]">
                  No Data Found
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AttachmentsList


