import React from 'react'
import {Button} from 'reactstrap'
import {useHistory} from 'react-router-dom'
import '../../styles/machine_card.css'
import ComingSoonImg from '../../assets/images/Machines/coming_soon_img.jpg'
import {currencyFormat} from '../../utils/helper'

function MachineCard({machineData}) {
  const history = useHistory()

  const handleOnPressCall = () => {
    if (machineData?.dealer?.contact_user?.phone) {
      return `callto:${machineData?.dealer?.contact_user?.phone}`
    } else if (machineData?.dealer?.contact_user?.email) {
      return `mailto:${machineData?.dealer?.contact_user?.email}`
    } else {
      return 'javascript:void(0)'
    }
  }

  return (
    <div className="flex flex-row justify-center h-full">
      <div className="card flex flex-col justify-between bg-[#ffffff] w-96 h-[auto] rounded-md p-3 space-y-4 m-2 border border-slate-400">
        <div
          className="cursor-pointer"
          onClick={() => {
            const encodeURICom = encodeURIComponent(
              `${machineData.assetAdvertisementId}-${machineData?.year || ''}-${
                machineData?.manufacturer?.name
              }-${machineData?.model?.number}`,
            )
            history.push({
              pathname: `/detail/${encodeURICom}`,
              state: {id: machineData},
            })
          }}
        >
          <img
            className="w-full h-80 hover:bg-cyan-300 object-cover"
            src={
              machineData?.primary_image
                ? machineData.primary_image.url
                : ComingSoonImg
            }
            alt=""
          />
        </div>

        <div id="description" className="space-y-1">
          <div
            className="cursor-pointer"
            onClick={() => {
              const encodeURICom = encodeURIComponent(
                `${machineData.assetAdvertisementId}-${
                  machineData?.year || ''
                }-${machineData?.manufacturer?.name}-${
                  machineData?.model?.number
                }`,
              )
              history.push({
                pathname: `/detail/${encodeURICom}`,
                state: {id: machineData},
              })
            }}
          >
            <h2 className="text-cyan-300  text-md transition hover:text-cyan-500 price-text">
              {machineData?.year > 0 && machineData.year}{' '}
              {machineData?.manufacturer?.name && machineData.manufacturer.name}{' '}
              {machineData?.model?.number && machineData.model.number}
            </h2>
          </div>
          <div>
            {machineData?.advertised_price?.fractional != 0 ? (
              <p className="text-yellow-500 text-lg select-none ">
                {machineData?.advertised_price?.currency?.symbol}{' '}
                {currencyFormat(
                  Number(machineData?.advertised_price?.fractional),
                )}
              </p>
            ) : (
              <a
                href={handleOnPressCall()}
                className="bg-cyan-600 text-white py-1 px-2 text-sm rounded disable:hover  "
              >
                Call for Pricing
              </a>
            )}

            {machineData?.meter_reading ? (
              <p className="mt-4 text-black text-md select-none">
                <b>Machine Hours:</b> {machineData?.meter_reading}
              </p>
            ) : (
              <p className="mt-4 text-black text-lg select-none">
                <b>Machine Hours:</b>
                <button className="bg-cyan-600 text-white py-1 px-2 mx-2 text-sm rounded">
                  Ask
                </button>
              </p>
            )}
          </div>
          <p className="text-black-500 text-md select-none h-5">
            {machineData?.location?.trim() || machineData?.location}
          </p>

          <div className="flex items-center  font-semibold text-sm">
            <button
              className="bg-yellow-500 hover:bg-yellow-600  text-black font-normal py-2 px-4 rounded"
              onClick={() => {
                const encodeURICom = encodeURIComponent(
                  `${machineData.assetAdvertisementId}-${
                    machineData?.year || ''
                  }-${machineData?.manufacturer?.name}-${
                    machineData?.model?.number
                  }`,
                )
                history.push({
                  pathname: `/contact-us/${encodeURICom}`,
                  state: {id: machineData},
                })
              }}
            >
              Contact Us
            </button>

            <Button
              className="bg-black hover:bg-black-700 text-white m-2 font-normal py-2 px-4 rounded"
              onClick={() => {
                const encodeURICom = encodeURIComponent(
                  `${machineData.assetAdvertisementId}-${
                    machineData?.year || ''
                  }-${machineData?.manufacturer?.name}-${
                    machineData?.model?.number
                  }`,
                )
                history.push({
                  pathname: `/detail/${encodeURICom}`,
                  state: {id: machineData},
                })
              }}
            >
              Details
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MachineCard
