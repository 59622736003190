import React from "react";
import ReactPlayer from "react-player";
import "../../styles/sell_machine.css";
const BreakLease = () => {
  return (
    <React.Fragment>
      <div className="break_lease_bg">
        <div className="p-20">
          <label className="font-semibold text-5xl text-gray-500 p-10">
            {" "}
            Break My Lease
          </label>
        </div>
      </div>
      <div class="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44 mt-10">
        <div className="p-10">
          <div className="flex py-5 flex-col-reverse sm:flex-col-reverse md:flex-col-reverse lg:flex-row">
            <div className="m-4">
              <p>
                Dewitt Equipment Remarketing is here to help get contractors and
                fleet managers out of problem situations.
                <br />
                <br /> It may be a now inconvenient or expensive finance or
                lease agreement that’s choking your cash and your dealer can’t
                or won’t help. Let’s get you out of it starting today.
                <br /> <br /> You may simply need a 20 tonne excavator for new
                opportunity that’s just presented itself and you are not of the
                mind to commit to a lease or purchase of a new unit for $250,000
                to $300,000! Maybe something in the $125,000 range looks just
                right.
              </p>
            </div>
            <div className="w-full h-[300px] md:w-full lg:w-full 2xl:3/12 ">
              <ReactPlayer
                url="https://www.youtube.com/watch?time_continue=2&v=2b9h_2Et7nY&feature=emb_title"
                playing={true}
                volume={1}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <BreakLeaseForm /> */}
      {/* <BreakForm /> */}
    </React.Fragment>
  );
};
export default BreakLease;
